<template>
  <div class="shop-order-pick">
    <WsLoading v-if="loading.fetching"></WsLoading>
    <div v-else>
      <div
        v-for="(modeldata, modeldataIndex) in modeldatas"
        :key="modeldataIndex"
      >
        <div class="page-break">
          <div class="pdf-file">
            <div class="wrap">
              <div class="header">
                <div>
                  <h1>
                    放心初蔬果網訂購單（內部留存）
                  </h1>
                </div>
              </div>
              <table width="100%">
                <tr class="table-item">
                  <td class="title w-10per">
                    活動
                  </td>
                  <td class="content w-40per"></td>
                  <td class="title w-10per">配送時段</td>
                  <td class="content w-40per">{{$_getShipTime(modeldata)}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per">訂單編號</td>
                  <td class="content w-40per">{{modeldata.no}}</td>
                  <td class="title w-10per">訂購資訊</td>
                  <td class="content w-40per">{{modeldata.orderer}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per gray-bg">收件姓名</td>
                  <td class="content w-40per gray-bg">{{modeldata.receiver}}</td>
                  <td class="title w-10per gray-bg">訂購時間</td>
                  <td class="content w-40per gray-bg">{{$moment(modeldata.created_at).format('YYYY-MM-DD HH:mm:ss')}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per gray-bg">電話</td>
                  <td class="content w-40per gray-bg">{{modeldata.receiver_tel}}</td>
                  <td class="title w-10per gray-bg">收件方式</td>
                  <td class="content w-40per gray-bg">{{$_getReceiveWayText(modeldata)}}</td>
                </tr>
              </table>
              <table width="100%">
                <tr class="table-item">
                  <td class="title w-10per gray-bg">收件地址</td>
                  <td class="content w-90per gray-bg">{{modeldata.receive_address}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per gray-bg">收件備註</td>
                  <td class="content w-90per gray-bg">{{modeldata.receive_remark}}</td>
                </tr>
              </table>
              <div>
                <div class="header">
                  <h1>訂購商品</h1>
                </div>
              </div>
              <table
                width="100%"
                style="table-layout: fixed;"
              >
                <tr class="table-item">
                  <td
                    class="title"
                    style="width:5%;"
                  >序號</td>
                  <td
                    class="title"
                    style="width:20%;"
                  >商品編號</td>
                  <td
                    class="title"
                    style="width:30%;"
                  >品名</td>
                  <td
                    class="title"
                    style="width:10%;"
                  >規格</td>
                  <td
                    class="title"
                    style="width:17%;"
                  >重量/容量</td>
                  <td
                    class="title"
                    style="width:8%;"
                  >儲位</td>
                  <td
                    class="title"
                    style="width:10%;"
                  >數量</td>
                </tr>
                <tr
                  v-for="(shop_order_shop_product, shop_order_shop_productIndex) in $_getSortedShopOrderShopProducts(modeldata.shop_order_shop_products)"
                  :key="shop_order_shop_productIndex"
                  class="table-item"
                >
                  <td
                    class="content"
                    style="width:5%;"
                  >{{shop_order_shop_productIndex + 1}}</td>
                  <td
                    class="content"
                    style="width:20%;"
                  >{{shop_order_shop_product.no?shop_order_shop_product.no:shop_order_shop_product.shop_product?shop_order_shop_product.shop_product.no:''}}</td>
                  <td
                    class="content"
                    style="width:30%;"
                  >{{shop_order_shop_product.name}}</td>
                  <td
                    class="content"
                    style="width:10%;"
                  >{{shop_order_shop_product.spec}}</td>
                  <td
                    class="content"
                    style="width:17%;"
                  >{{shop_order_shop_product.weight_capacity}} {{shop_order_shop_product.weight_capacity_unit?shop_order_shop_product.weight_capacity_unit:shop_order_shop_product.shop_product?shop_order_shop_product.shop_product.weight_capacity_unit:''}} ± 10%</td>
                  <td
                    class="content"
                    style="width:8%;"
                  >{{shop_order_shop_product.storage_space?shop_order_shop_product.storage_space:shop_order_shop_product.shop_product?shop_order_shop_product.shop_product.storage_space:''}}</td>
                  <td
                    class="content"
                    style="width:10%;"
                  >{{shop_order_shop_product.count}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="page-break">
          <div class="pdf-file">
            <div class="wrap">
              <div class="header">
                <div>
                  <h1>
                    放心初蔬果網訂購單
                  </h1>
                </div>
              </div>
              <table width="100%">
                <tr class="table-item">
                  <td class="title w-10per">
                    活動
                  </td>
                  <td class="content w-40per">
                  </td>
                  <td class="title w-10per">配送時段</td>
                  <td class="content w-40per">{{$_getShipTime(modeldata)}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per">訂單編號</td>
                  <td class="content w-40per">{{modeldata.no}}</td>
                  <td class="title w-10per">訂購資訊</td>
                  <td class="content w-40per">{{$_encode(modeldata.orderer)}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per gray-bg">收件姓名</td>
                  <td class="content w-40per">{{$_encode(modeldata.receiver)}}</td>
                  <td class="title w-10per gray-bg">訂購時間</td>
                  <td class="content w-40per gray-bg">{{$moment(modeldata.created_at).format('YYYY-MM-DD HH:mm:ss')}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per gray-bg">電話</td>
                  <td class="content w-40per">{{$_encode(modeldata.receiver_tel,'tel')}}</td>
                  <td class="title w-10per gray-bg">收件方式</td>
                  <td class="content w-40per gray-bg">{{$_getReceiveWayText(modeldata)}}</td>
                </tr>
              </table>
              <table width="100%">
                <tr class="table-item">
                  <td class="title w-10per gray-bg">收件地址</td>
                  <td class="content w-90per gray-bg">{{modeldata.receive_address}}</td>
                </tr>
                <tr class="table-item">
                  <td class="title w-10per gray-bg">收件備註</td>
                  <td class="content w-90per gray-bg">{{modeldata.receive_remark}}</td>
                </tr>
              </table>
              <div>
                <div class="header">
                  <h1>訂購商品</h1>
                </div>
              </div>
              <table
                width="100%"
                style="table-layout: fixed;"
              >
                <tr class="table-item">
                  <td
                    class="title"
                    style="width:5%;"
                  >序號</td>
                  <td
                    class="title"
                    style="width:20%;"
                  >商品編號</td>
                  <td
                    class="title"
                    style="width:35%;"
                  >品名</td>
                  <td
                    class="title"
                    style="width:10%;"
                  >規格</td>
                  <td
                    class="title"
                    style="width:15%;"
                  >重量/容量</td>
                  <td
                    class="title"
                    style="width:15%;"
                  >數量</td>
                </tr>
                <tr
                  v-for="(shop_order_shop_product, shop_order_shop_productIndex) in $_getSortedShopOrderShopProducts(modeldata.shop_order_shop_products)"
                  :key="shop_order_shop_productIndex"
                  class="table-item"
                >
                  <td
                    class="content"
                    style="width:5%;"
                  >{{shop_order_shop_productIndex + 1}}</td>
                  <td
                    class="content"
                    style="width:20%;"
                  >{{shop_order_shop_product.no?shop_order_shop_product.no:shop_order_shop_product.shop_product?shop_order_shop_product.shop_product.no:''}}</td>
                  <td
                    class="content"
                    style="width:35%;"
                  >{{shop_order_shop_product.name}}</td>
                  <td
                    class="content"
                    style="width:10%;"
                  >{{shop_order_shop_product.spec}}</td>
                  <td
                    class="content"
                    style="width:10%;"
                  >{{shop_order_shop_product.weight_capacity}} {{shop_order_shop_product.weight_capacity_unit?shop_order_shop_product.weight_capacity_unit:shop_order_shop_product.shop_product?shop_order_shop_product.shop_product.weight_capacity_unit:''}} ± 10%</td>
                  <td
                    class="content"
                    style="width:15%;"
                  >{{shop_order_shop_product.count}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import S_ShopOrder from "@/__vue2stone_cms/service/api/v1/shop_order";
export default {
  data() {
    return {
      modeldatas: [],
      loading: {
        fetching: true,
      },
      items: {
        receive_way: [
          {
            value: "phone-contact",
            text: "電話聯絡收件人",
          },
          {
            value: "phone-contact-building-manager",
            text: "電聯收件人後，交由管理室代收",
          },
          {
            value: "building-manager",
            text: "不需電聯，直接交由管理室代收",
          },
        ],
      },
    };
  },
  methods: {
    $_getSortedShopOrderShopProducts(shop_order_shop_products) {
      const _shopOrderShopProducts = [...shop_order_shop_products];
      return _shopOrderShopProducts.sort((a, b) => {
        const spA = a.storage_space
          ? a.storage_space
          : a.shop_product
          ? a.shop_product.storage_space
          : "";
        const spB = b.storage_space
          ? b.storage_space
          : b.shop_product
          ? b.shop_product.storage_space
          : "";
        if (spB < spA) {
          return 1;
        }
        if (spB > spA) {
          return -1;
        }
        // if (!a.shop_product) {
        //   return 1;
        // }
        // if (!b.shop_product) {
        //   return -1;
        // }
        // if (b.shop_product.storage_space < a.shop_product.storage_space) {
        //   return 1;
        // }
        // if (b.shop_product.storage_space > a.shop_product.storage_space) {
        //   return -1;
        // }
        return 0;
      });
    },
    $_encode(value, type = "text") {
      if (!value) {
        return "";
      }
      if (type == "text") {
        const len = value.length;
        let _value = value.substring(0, 1);
        for (let index = 0; index < len - 2; index++) {
          _value += "*";
        }
        _value += value.substring(len - 1, len);
        return _value;
      } else if (type == "tel") {
        return value.substring(0, 3) + "***" + value.substring(7, 10);
      } else {
        return value;
      }
    },
    $_getShipTime(modeldata) {
      return `
      ${this.$moment(modeldata.ship_date).format("YYYY-MM-DD")}
      /
      ${this.$moment(modeldata.ship_start_time).format("HH:mm:ss")}
      - 
      ${this.$moment(modeldata.ship_end_time).format("HH:mm:ss")}
      `;
    },
    $_getReceiveWayText(modeldata) {
      const receiveWay = this.items.receive_way.find((e) => {
        return e.value == modeldata.receive_way;
      });
      return receiveWay ? receiveWay.text : "-";
      // return this.$moment(modeldata.ship_date).format("YYYY-MM-DD");
    },
    async $_init() {
      try {
        this.loading.fetching = true;
        this.modeldatas = await S_ShopOrder.index_print({
          ...this.$route.query,
        });
        this.loading.fetching = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.$_init();
  },
};
</script>

<style lang="scss">
.shop-order-pick {
  width: 640px;
  margin: 0 auto;
  @media print {
    .page-break {
      clear: both;
      page-break-after: always;
    }
  }
  .page-break {
    padding-bottom: 40px;
  }
  .ws-loading {
    color: black;
    .lds-ellipsis {
      color: black;
      div {
        color: black;
        background-color: var(--gray);
      }
    }
  }

  .page-break {
    page-break-after: always;
  }

  * {
    font-family: TaipeiSansTCBeta;
  }

  .pdf-file {
    padding: 0px 10px;
  }
  .wrap {
    border: 1px solid #222;
  }
  .header {
    height: 32px;
    background-color: #efeeee;
    color: #393d43;
    padding: 2px 2px;
  }
  .title {
    padding: 2px 2px;
    font-size: 12px;
  }
  .w-10per {
    width: 10%;
  }
  .w-40per {
    width: 40%;
  }
  .w-90per {
    width: 90%;
  }
  .content {
    padding: 2px 2px;
    font-size: 12px;
  }
  td {
    border: 1px solid #222;
  }
  td.gray-bg {
    background-color: #ccc;
  }
  h1 {
    font-size: 14px;
  }
  h2 {
    font-size: 12px;
  }
}
</style>